import React from "react";
import * as Yup from "yup";
import { Link } from 'gatsby'

import { ApolloProvider } from "@apollo/client"
import { client } from "../../../app/provider/apollo/client"

import { QUERY } from "../../../queries/passwordForgot"
import { FormWrapper } from "./passwordForgotFormWrapper"

const PasswordForgotForm = (props) => {

    const schema = {
        email: Yup.string().email("Invalid Email Address").required("*Required"),
    };

    const inputFields = {
        email: {
            // label: "Email:"
            placeholder: "Email"
        }
    };

    const sundries = (
        <React.Fragment>
            <div>Back to <Link to="/login">login</Link></div>
        </React.Fragment>
    )

    return (
        <ApolloProvider client={client}>
            <FormWrapper
                ctaText="Get Reset Link"
                dbQueryName="passwordForgot"
                successRoute="/password/forgot/thank-you"
                submitErrMsg="Try again!"
                inputFields={inputFields}
                schema={schema}
                query={QUERY}
                sundries={sundries}
            />
        </ApolloProvider>
    );
};

export { PasswordForgotForm };
// export { QUERY }