import React from "react"

import { ModalLayout } from "../../layouts/modal"

import { PasswordForgotForm } from "../../forms/password/forgot"

const Page = () => {

    const content = {}
    content.htmlTitle = `Forgot Password`
    content.pageTitle = `Password Reset Link`
    content.pageTagline = <div>Looks like you have egg on your face!<br/>Let's get you cleaned up.</div>

    const form = <PasswordForgotForm />;

    return (
        <ModalLayout {...content} form={form} />
    )

}

export default Page